// カテゴリ・タグ一覧
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { BigBanner } from '../components/adsense';

const CategoryTag = props => {
  //タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const title = `カテゴリ・タグ一覧 | ${siteName}`;
  const description = 'カテゴリ・タグの一覧ページです。';

  // カテゴリ・タグの一覧を作成
  const blogDataList = props.data.allMdx.nodes;
  let categoryList = [];
  let tagList = [];
  blogDataList.forEach(blogData => {
    let { category, tags } = blogData.frontmatter;
    // カテゴリ データがあるなら配列に入れる
    if (category) {
      categoryList.push(category);
    }
    // タグが配列なら タグ一覧配列に追加 合成
    if (Array.isArray(tags)) {
      tagList = tagList.concat(tags);
    }
  });
  // 重複しているカテゴリとタグを消す
  categoryList = Array.from(new Set(categoryList));
  tagList = Array.from(new Set(tagList));

  return (
    <Layout title={title} description={description} pageType="category-tag">
      {/* カテゴリ */}
      <div className="mb-8">
        <h1 className="text-2xl font-black">カテゴリ</h1>
        {categoryList.map((category, i) => (
          <div className="inline-block" key={i}>
            <Link
              to={`/category/${category}`}
              className="mt-1 mr-2 flex items-center hover:text-blue-300"
            >
              <span className="material-icons">folder_open</span>
              {category}
            </Link>
          </div>
        ))}
      </div>
      <BigBanner className="mx-auto mb-8" />
      {/* タグ */}
      <div className="mb-8">
        <h1 className="text-2xl font-black">タグ</h1>
        {tagList.map((tag, i) => (
          <Link
            key={i}
            to={`/tag/${tag}`}
            className="inline-block mt-1 mr-1 py-1 px-4 border-solid border-2 border-blue-300
                                         rounded-full hover:bg-blue-300 hover:text-white"
          >
            {tag}
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export default CategoryTag;

export const allCategoryTagQuery = graphql`
  query allCategoryTagQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      nodes {
        frontmatter {
          category
          tags
        }
      }
    }
  }
`;
